import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function ExploreCard({ item }) {
  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      xl={3}
      padding="1vh 0"
      display="flex"
      justifyContent='center'
    >
      <Link to={"/blogs/" + item.id}>
        <Card
          sx={{
            padding: "20px",
            maxWidth: "260px",
            borderRadius: "10px",
          }}
        >
          <CardMedia
            component="img"
            height="140"
            image={item.bannerImg.url}
            sx={{ borderRadius: "10px", width: "260px", height: "200px" }}
          />
          <CardContent sx={{ padding: "0" }}>
            <Typography fontSize="20px" padding="20px 0" fontWeight="bold">
              {item.heading.substr(0,35)}...
            </Typography>
            <Box display="flex">
              <Box borderRadius="50%" height='70px' width='70px' overflow='hidden'>
                <img src={item.authorImgSmall} height='70px' width='70px'/>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                padding="0 10px"
              >
                <Typography color="#2B2C34" fontSize="16px" fontWeight="600">
                  {item.author}
                </Typography>
                <Typography fontSize="14px" color="#494950">
                  {item.date}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
}
