import './App.css';
import {Contexts} from './vendors/Contexts';
import HomePage from './pages/Home Page/HomePage';
import Signup from './pages/Auths/Signup';
import Signin from './pages/Auths/Signin';
import Forgotpassword from './pages/Auths/Forgotpassword';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Itinerary from './pages/Iitinerary/Iitinerary';
import AuthsContext from './pages/Auths/AuthsContext';
import Resetpassword from './pages/Auths/Restpassword';
import Privacy from './pages/policies/Privacy';
import Cookies from './pages/policies/Cookies';
import ConfirmEmail from './pages/Email Confirmation/ConfirmEmail';
import VerifyEmail from './pages/Email Confirmation/VerifyEmail';
import Verificationforrest from './pages/Auths/Verificationforrest';
import Session from './pages/Sessions';
import IitineraryContext from './pages/IitineraryContext';
import Hotels from './pages/Hotels/Hotels'
import HotelsList from './pages/Hotels-list/HotelsList';
import PageNotFound from './pages/PageNotFound';
import HotelNotFound from './pages/HotelNotFound';
import Blogs from './pages/Blogs/Blogs';
import Explore from './pages/explore/Explore';
import ComingSoon from './pages/ComingSoon';
import { ThemeProvider } from '@mui/material/styles';
import theme from './vendors/Theme'; // Import your custom theme
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import LandingPage from './pages/Home Page/LandingPage';
import Flights from './pages/Flights/Flights';
import FlightsList from './pages/Flights/FlightsList';
import ExploreBlogs from './pages/explore/AllBlogs';
import StickyScrollPage from './Try';

function App() {
  return (
   <>
   <Contexts>
   <ThemeProvider theme={theme}>
    <Router>
    <Routes>

<Route path="/home1" element={<Session><HomePage/></Session>}></Route>
{/* <Route path="/home1" element={<HomePage/>}></Route> */}
<Route path="/" element={<LandingPage/>}></Route>
<Route path="/flights" element={<Flights/>}></Route>
<Route path="/flightslist" element={<FlightsList/>}></Route>

<Route path="/hotelslist" element={<HotelsList/>}></Route>
<Route path="/blogs/:blogid" element={<Blogs/>}></Route>
<Route path="/explore" element={<Explore/>}></Route>
<Route path="/explore/:locationid" element={<ExploreBlogs/>}></Route>
<Route path="/itinerary" element={<IitineraryContext><Itinerary/></IitineraryContext>}></Route>
<Route path="/privacy" element={<Privacy/>}></Route>
<Route path="/cookie" element={<Cookies />}></Route>
<Route path="/restpassword" element={<Resetpassword/>}></Route>
<Route path="/confirmemail" element={<ConfirmEmail/>}></Route>
<Route path="/verifyemail" element={<VerifyEmail/>}></Route>
<Route path="/verifyresttoken" element={<Verificationforrest />}></Route>

<Route path="/signup" element={<AuthsContext><Signup/></AuthsContext>}></Route>
<Route path="/signin" element={<AuthsContext><Session><Signin/></Session></AuthsContext>}></Route>
<Route path="/forgotpassword" element={<Forgotpassword/>}></Route>
<Route path="/comingsoon" element={<ComingSoon/>}></Route>
<Route path="/hotelnotfound" element={<HotelNotFound/>}></Route>
<Route path="/about-us" element={<AboutUs/>}></Route>
<Route path="/contact-us" element={<ContactUs/>}></Route>
<Route path="/try" element={<StickyScrollPage/>}></Route>
<Route path='*' element={<PageNotFound/>}></Route>

</Routes>
</Router>
</ThemeProvider>
</Contexts>
   </>
  );
}

export default App;
