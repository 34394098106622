import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CookieConsent from 'react-cookie-consent';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <div style={{display:'flex', justifyContent:'center'}}>
      <div style={{maxWidth:'1536px'}}> */}
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="myAwesomeCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={1} // this value needs to be updated back to 150
      >
        This website uses cookies to enhance the user experience.{" "}
        <a href="/privacy" style={{ color: "#ffffff" }}>Learn more</a>
      </CookieConsent>
    <App />
    {/* </div>
    </div> */}

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
