import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ExploreComponent from "./ExploreComponent";
import Footer from "../../components/ui-components/Footer";
import Navbar from "../../components/ui-components/Navbar";
import blogsData from "../Blogs/blogsData";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function Explore() {
  const limit = 3;
  const groupBlogsByCountry = (blogs) => {
    return blogs.reduce((acc, blog) => {
      const { location } = blog;
      if (!acc[location]) {
        acc[location] = [];
      }
      acc[location].push(blog);
      return acc;
    }, {});
  };

  const groupedBlogs = groupBlogsByCountry(blogsData);

  console.log(groupedBlogs, "the gorouped data of blogsssss");
  // const limitedData = blogsData.slice(0, limit);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          backgroundImage: `url(assets/images/litineraryback.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Navbar currentPage="explore" />
      </Grid>
      <Box
        display="flex"
        flexDirection="column"
        padding={{ xs: "0 0 5vh 0", lg: "5vh 15vw" }}
      >
        <img src="./assets/images/explore-banner.webp" />
      </Box>
      <Box display="flex" justifyContent="center">
        <Box flexGrow="1" padding="0 5vw" maxWidth="1536px">
        <Box
            padding={{
              xs: "5vh 0 2vh 0",
              xs: "5vh 0 2vh 0",
              xs: "5vh 5vw 2vh 4vw",
            }}
            display="flex"
            justifyContent={{ lg: "space-between", xs: "center" }}
          >
            <Box>
              <Typography color="#2B2C34" fontSize="24px" fontWeight="700">
              Latest Articles
              </Typography>
            </Box>
          </Box>
          <ExploreComponent desiredLocation="all" />
          
          <Box
            padding={{
              xs: "5vh 0 2vh 0",
              xs: "5vh 0 2vh 0",
              xs: "5vh 5vw 2vh 4vw",
            }}
            display="flex"
            justifyContent={{ lg: "space-between", xs: "center" }}
          >
            <Box>
              <Typography color="#2B2C34" fontSize="24px" fontWeight="700">
                Australia
              </Typography>
            </Box>
            <Box>
              <Button
                href="/explore/Australia"
                sx={{
                  color: "#2B2C34",
                  fontSize: "20px",
                  fontWeight: "700",
                  textTransform: "capitalize",
                  display: { lg: "flex", xs: "none" },
                }}
              >
                See All <ChevronRightIcon />
              </Button>
            </Box>
          </Box>
          <ExploreComponent desiredLocation="Australia" />
          <Box display={{ lg: "none", xs: "flex" }} justifyContent="center">
            <Button
              href="/explore/Australia"
              sx={{
                color: "#2B2C34",
                fontSize: "20px",
                fontWeight: "700",
                textTransform: "capitalize",
              }}
            >
              See All <ChevronRightIcon />
            </Button>
          </Box>

          <Box
            padding={{
              xs: "5vh 0 2vh 0",
              xs: "5vh 0 2vh 0",
              xs: "5vh 5vw 2vh 4vw",
            }}
            display="flex"
            justifyContent={{ lg: "space-between", xs: "center" }}
          >
            <Box>
              <Typography color="#2B2C34" fontSize="24px" fontWeight="700">
                Europe
              </Typography>
            </Box>
            <Box>
              <Button
                href="/explore/europe"
                sx={{
                  color: "#2B2C34",
                  fontSize: "20px",
                  fontWeight: "700",
                  textTransform: "capitalize",
                  display: { lg: "flex", xs: "none" },
                }}
              >
                See All <ChevronRightIcon />
              </Button>
            </Box>
          </Box>
          <ExploreComponent desiredLocation="europe" />
          <Box display={{ lg: "none", xs: "flex" }} justifyContent="center">
            <Button
              href="/explore/europe"
              sx={{
                color: "#2B2C34",
                fontSize: "20px",
                fontWeight: "700",
                textTransform: "capitalize",
              }}
            >
              See All <ChevronRightIcon />
            </Button>
          </Box>

          <Box
            padding={{
              xs: "5vh 0 2vh 0",
              xs: "5vh 0 2vh 0",
              xs: "5vh 5vw 2vh 4vw",
            }}
            display="flex"
            justifyContent={{ lg: "space-between", xs: "center" }}
          >
            <Box>
              <Typography color="#2B2C34" fontSize="24px" fontWeight="700">
                South America
              </Typography>
            </Box>
            <Box>
              <Button
                href="/explore/south america"
                sx={{
                  color: "#2B2C34",
                  fontSize: "20px",
                  fontWeight: "700",
                  textTransform: "capitalize",
                  display: { lg: "flex", xs: "none" },
                }}
              >
                See All <ChevronRightIcon />
              </Button>
            </Box>
          </Box>
          <ExploreComponent desiredLocation="south america" />
          <Box display={{ lg: "none", xs: "flex" }} justifyContent="center">
            <Button
              href="/explore/south america"
              sx={{
                color: "#2B2C34",
                fontSize: "20px",
                fontWeight: "700",
                textTransform: "capitalize",
              }}
            >
              See All <ChevronRightIcon />
            </Button>
          </Box>

          <Box
            padding={{
              xs: "5vh 0 2vh 0",
              xs: "5vh 0 2vh 0",
              xs: "5vh 5vw 2vh 4vw",
            }}
            display="flex"
            justifyContent={{ lg: "space-between", xs: "center" }}
          >
            <Box>
              <Typography color="#2B2C34" fontSize="24px" fontWeight="700">
                North America
              </Typography>
            </Box>
            <Box>
              <Button
                href="/explore/north america"
                sx={{
                  color: "#2B2C34",
                  fontSize: "20px",
                  fontWeight: "700",
                  textTransform: "capitalize",
                  display: { lg: "flex", xs: "none" },
                }}
              >
                See All <ChevronRightIcon />
              </Button>
            </Box>
          </Box>
          <ExploreComponent desiredLocation="north america" />
          <Box display={{ lg: "none", xs: "flex" }} justifyContent="center">
            <Button
              href="/explore/north america"
              sx={{
                color: "#2B2C34",
                fontSize: "20px",
                fontWeight: "700",
                textTransform: "capitalize",
              }}
            >
              See All <ChevronRightIcon />
            </Button>
          </Box>

          <Box
            padding={{
              xs: "5vh 0 2vh 0",
              xs: "5vh 0 2vh 0",
              xs: "5vh 5vw 2vh 4vw",
            }}
            display="flex"
            justifyContent={{ lg: "space-between", xs: "center" }}
          >
            <Box>
              <Typography color="#2B2C34" fontSize="24px" fontWeight="700">
                Africa
              </Typography>
            </Box>
            <Box>
              <Button
                href="/explore/africa"
                sx={{
                  color: "#2B2C34",
                  fontSize: "20px",
                  fontWeight: "700",
                  textTransform: "capitalize",
                  display: { lg: "flex", xs: "none" },
                }}
              >
                See All <ChevronRightIcon />
              </Button>
            </Box>
          </Box>
          <ExploreComponent desiredLocation="africa" />
          <Box display={{ lg: "none", xs: "flex" }} justifyContent="center">
            <Button
              href="/explore/africa"
              sx={{
                color: "#2B2C34",
                fontSize: "20px",
                fontWeight: "700",
                textTransform: "capitalize",
              }}
            >
              See All <ChevronRightIcon />
            </Button>
          </Box>

          <Box
            padding={{
              xs: "5vh 0 2vh 0",
              xs: "5vh 0 2vh 0",
              xs: "5vh 5vw 2vh 4vw",
            }}
            display="flex"
            justifyContent={{ lg: "space-between", xs: "center" }}
          >
            <Box>
              <Typography color="#2B2C34" fontSize="24px" fontWeight="700">
                Asia
              </Typography>
            </Box>
            <Box>
              <Button
                href="/explore/asia"
                sx={{
                  color: "#2B2C34",
                  fontSize: "20px",
                  fontWeight: "700",
                  textTransform: "capitalize",
                  display: { lg: "flex", xs: "none" },
                }}
              >
                See All <ChevronRightIcon />
              </Button>
            </Box>
          </Box>
          <ExploreComponent desiredLocation="asia" />
          <Box display={{ lg: "none", xs: "flex" }} justifyContent="center">
            <Button
              href="/explore/asia"
              sx={{
                color: "#2B2C34",
                fontSize: "20px",
                fontWeight: "700",
                textTransform: "capitalize",
              }}
            >
              See All <ChevronRightIcon />
            </Button>
          </Box>

          <Box
            padding={{
              xs: "5vh 0 2vh 0",
              xs: "5vh 0 2vh 0",
              xs: "5vh 5vw 2vh 4vw",
            }}
            display="flex"
            justifyContent={{ lg: "space-between", xs: "center" }}
          >
            <Box>
              <Typography color="#2B2C34" fontSize="24px" fontWeight="700">
                General Travel Tips
              </Typography>
            </Box>
            <Box>
              <Button
                href="/explore/General Travel Tips"
                sx={{
                  color: "#2B2C34",
                  fontSize: "20px",
                  fontWeight: "700",
                  textTransform: "capitalize",
                  display: { lg: "flex", xs: "none" },
                }}
              >
                See All <ChevronRightIcon />
              </Button>
            </Box>
          </Box>
          <ExploreComponent desiredLocation="General Travel Tips" />
          <Box
            display={{ lg: "none", xs: "flex" }}
            mb={{ xs: "5vh", lg: "0" }}
            justifyContent="center"
          >
            <Button
              href="/explore/General Travel Tips"
              sx={{
                color: "#2B2C34",
                fontSize: "20px",
                fontWeight: "700",
                textTransform: "capitalize",
              }}
            >
              See All <ChevronRightIcon />
            </Button>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
